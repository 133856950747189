<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formEmitente" lazy-validation>
      <v-container fluid>
        <v-widget title="Emitente" :temCampoObrigatorio="true">
          <template v-slot:widget-content>
            <v-tabs v-model="abaAtiva">
              <v-tab :key="1">Dados do Emitente</v-tab>
              <v-tab :key="2">NF-e</v-tab>
              <v-tab :key="3">Configurações Fiscais</v-tab>
              <v-tab :key="4">Certificado</v-tab>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider></v-divider>

              <v-tab-item :key="1" eager>
                <v-container grid-list-md fluid>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="CNPJ *" :value="emitente.cnpj | formatarCnpj" :loading="carregando" v-mask="'##.###.###/####-##'" required readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Inscrição Estadual *" v-model="emitente.ie" :rules="validarInscricao" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Inscrição Municipal" v-model="emitente.im"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Razão Social *" v-model="emitente.razao" required :rules="validadarrazaoSocialNome"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Nome Fantasia" v-model="emitente.fantasia"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <campo-cep v-mask="'#####-###'" label="CEP" buscarcep @resultado="setEndereco" v-model="emitente.cep" append-icon="map" data-cy="cep"></campo-cep>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="Logradouro *" v-model="emitente.logradouro" :rules="validarLogradouro"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Número *" v-model="emitente.numero" :rules="validarNumero"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Complemento" v-model="emitente.complemento"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Bairro *" v-model="emitente.bairro" :rules="validarBairro"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete label="Estado" v-model="emitente.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(emitente.estado)"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        label="Cidade *"
                        v-model="emitente.codigo_cidade"
                        @change="setCidade(emitente.codigo_cidade)"
                        :items="cidades"
                        :rules="validarCidade"
                        :disabled="cidades.length == 0 || $store.state.emitente.carregando_cidade"
                        :loading="$store.state.emitente.carregando_cidade"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="País *" v-model="emitente.pais" :rules="validarPais" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Telefone" v-model="emitente.telefone" v-mask="'(##)####-####'"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="E-Mail *" v-model="emitente.email" :rules="validarEmail"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="2" eager>
                <v-container grid-list-md fluid>
                  <v-row dense>
                    <v-col cols="12" sm="2">
                      <v-select label="Versão NF-e" v-model="emitente.versao" :items="constantes.VERSAO_NFE"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select label="Ambiente *" v-model="emitente.ambiente" :items="constantes.AMBIENTES" :rules="validarAmbiente"></v-select>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-select label="Formato de Impressão" v-model="emitente.formato_impressao" :items="constantes.FORMATO_IMPRESSAO"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="Série Padrão NF-e" v-model="emitente.configuracao_tributaria.serie_padrao" v-mask="'###'" :rules="validarSerie" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-select label="Frete Padrão *" v-model="emitente.frete_padrao" :items="constantes.MODELO_FRETE" :rules="validarFretePadrao"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select label="Forma de Pgto. Padrão *" v-model="emitente.forma_pagamento_padrao" :items="constantes.FORMA_PAGAMENTO" :rules="validarFormaPgtoPadrao"></v-select>
                    </v-col>
                    <v-col cols="12" sm="5" v-if="emitente.plano === 'deposito'">
                      <v-select label="Calcular Preço de Custo" v-model="emitente.calculo_custo" :items="constantes.CALCULOS_PRECO_CUSTO"></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="3" eager>
                <v-container grid-list-md fluid>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="CNAE *" v-model="emitente.cnae" v-mask="'####-#/##'" @input="consultarCnae(emitente.cnae)" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="Descrição da Atividade Econômica" v-model="emitente.descricao_cnae" readonly required :rules="validarDescCnae"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Ramo de Atividade *" @change="atualizarConfigCfop()" v-model="emitente.configuracao_tributaria.ramo_atividade" :items="constantes.RAMO_ATIVIDADES" required :rules="validarRamoAtiv"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Configuração CFOP *" @change="atualizarConfigCfop()" v-model="emitente.configuracao_tributaria.config_cfop_padrao" :items="constantes.CONFIG_CFOP_PADRAO"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="emitente.configuracao_tributaria.config_cfop_padrao !== '1'">
                      <v-text-field label="CFOP Padrão *" v-mask="'####'" v-model="emitente.configuracao_tributaria.cfop_padrao" :rules="validarCfopPadrao"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Regime Tributário *" v-model="emitente.configuracao_tributaria.regime_tributario" :items="constantes.REGIMES_TRIBUTARIOS" required :rules="validarRegime"></v-select>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-select label="CSOSN *" :items="constantes.CSOSN" v-model="emitente.configuracao_tributaria.csosn" @change="definirInfoComplementar()" required :rules="validarCsosn"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Alíq. Créd. SN" v-model="emitente.configuracao_tributaria.aliq_cred_sn" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-textarea label="Informações Complementares" v-model="emitente.configuracao_tributaria.info_complementar" required></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="4" eager>
                <v-container grid-list-md fluid>
                  <v-row dense v-if="certificado">
                    <v-col cols="12" sm="12" class="mb-3">
                      <span class="subtitle-1">Informações do Certificado</span>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="CNPJ" :value="certificado.solicitante.cnpj | formatarCnpj" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <v-text-field label="Emitido Para" :value="certificado.solicitante.razao" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Data de Emissão" :value="certificado.emissao | formatarData" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Data de Validade" :value="certificado.validade | formatarData" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Dias de Certificado" :value="diasRestantes" readonly></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </template>
          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn color="primary" :loading="carregando" @click="salvar()" :disabled="!valid">Salvar</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask,
  },

  data: () => ({
    valid: false,
    validarSerie: [(v) => !!v || 'Série obrigatória', (v) => (v && v.length === 3) || 'Formato Inválido, ex: 001'],
    validarInscricao: [(v) => !!v || 'Inscrição Obrigatória'],
    validadarrazaoSocialNome: [(v) => !!v || 'Razão/Nome Obrigatório'],
    validarCep: [(v) => !!v || 'CEP Obrigatório'],
    validarLogradouro: [(v) => !!v || 'Logradouro Obrigatório'],
    validarNumero: [(v) => !!v || 'Número Obrigatório'],
    validarBairro: [(v) => !!v || 'Bairro Obrigatório'],
    validarCidade: [(v) => !!v || 'Cidade Obrigatória'],
    validarPais: [(v) => !!v || 'País Obrigatório'],
    validarAmbiente: [(v) => !!v || 'Ambiente Obrigatório'],
    validarFretePadrao: [(v) => !!v || 'Frete Padrão Obrigatório'],
    validarFormaPgtoPadrao: [(v) => !!v || 'Frete Padrão Obrigatório'],
    validarTipoCertificado: [(v) => !!v || 'Tipo de Cert. Obrigatório'],
    validarDescCnae: [(v) => !!v || 'Informe um CNAE válido'],
    validarRamoAtiv: [(v) => !!v || 'Ramo Obrigatório'],
    validarCfopPadrao: [(v) => !!v || 'CFOP Padrão Obrigatório'],
    validarRegime: [(v) => !!v || 'Regime Trib. Obrigatório'],
    validarCsosn: [(v) => !!v || 'CSOSN Obrigatório'],
    validarEmail: [(v) => (v && validador.email(v)) || 'E-mail Inválido'],
    constantes: constantes,
    certLocal: {
      arquivo: [],
      senha: '',
    },
    cidadeSelecionada: {},
    abaAtiva: 0,
    loading: false,
  }),

  computed: {
    ...mapState('emitente', {
      emitente: 'emitente',
      carregando: 'carregando',
      certificadoCarregado: 'certificadoCarregado',
      certificado: 'certificado',
    }),

    ...mapGetters('emitente', {
      diasRestantes: 'diasRestantesCertificado',
      cidades: 'listaCidades',
    }),
  },

  methods: {
    ...mapActions('emitente', {
      filtrarCidadesEstado: 'filtrarCidadesEstado',
      consultarCertificado: 'consultarCertificadoEmitente',
      carregar: 'carregarEmitente',
    }),

    ...mapMutations('emitente', {
      setCidade: 'setCidade',
      atualizarConfigCfop: 'atualizarConfigCfop',
    }),

    async salvar() {
      if (this.$refs.formEmitente.validate()) {
        try {
          const inscricao = `${this.emitente.ie}-${this.emitente.estado}`;
          const isIncricaoValida = validador.inscricaoEstadual(inscricao);
          if (isIncricaoValida) {
            await this.$store.dispatch('emitente/salvar');
          } else {
            this.$store.commit('setMensagemErro', `Inscrição ${inscricao} inválida`);
          }
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$router.push('/home');
    },

    enviarCert() {
      const arquivo = this.certLocal.arquivo;
      if (arquivo && arquivo.name) {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          vm.atualizarConteudo(e.target.result);
        };
        reader.readAsBinaryString(arquivo, 'ASCII');
      } else {
        this.$store.commit('setMensagemErro', 'Certificado não informado.');
      }
    },

    atualizarConteudo(certificado) {
      if (certificado) {
        this.certLocal.arquivo = btoa(certificado);
        this.$store.dispatch('emitente/processarCertificado', this.certLocal);
        this.certLocal = {
          arquivo: [],
          senha: '',
        };
        this.$refs.formEmitente.resetValidation();
      }
    },

    setEndereco(endereco) {
      this.$store.commit('emitente/setEndereco', endereco);
      this.filtrarCidadesEstado(this.emitente.estado);
    },

    consultarCnae(cnae) {
      if (cnae.length === 9) {
        const cnaeConsulta = cnae.replace('-', '').replace('/', '');
        this.$store.dispatch('emitente/consultarCnae', cnaeConsulta);
      }
    },

    definirInfoComplementar() {
      const configTributaria = this.$store.state.emitente.emitente.configuracao_tributaria;
      if (configTributaria) {
        if (configTributaria.regime_tributario == 1 || configTributaria.regime_tributario == 2) {
          if (configTributaria.csosn == 101) {
            const texto =
              'DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL, ' +
              'NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI ' +
              'PERMITE O APROVEITAMENTO DO CRÉDITO DE ICMS CORRESPONDENTE À ALÍQUOTA DE ' +
              configTributaria.aliq_cred_sn +
              '% ' +
              'NOS TERMOS DO ART. 23 DA LEI COMPLEMENTAR Nº 123, DE 2006';
            this.$store.commit('emitente/setInfoComplementar', texto);
          } else if (configTributaria.csosn == 102) {
            const texto = 'DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL' + 'NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI';
            this.$store.commit('emitente/setInfoComplementar', texto);
          } else {
            this.$store.commit('emitente/setInfoComplementar', '');
          }
        } else {
          this.$store.commit('emitente/setInfoComplementar', '');
        }
      } else {
        this.$store.commit('emitente/setInfoComplementar', '');
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('emitente/setCidades', []);
    next();
  },

  async created() {
    try {
      this.loading = true;

      await this.carregar(this.$store.session.empresa.id);

      const emitente = this.$store.state.emitente.emitente;
      if (emitente && emitente._id && emitente._id !== '') {
        await this.filtrarCidadesEstado(emitente.estado);
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
